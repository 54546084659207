<template>
  <div>
    <Header class="" />
    <section
      v-if="plans"
      id="pricing-plan"
    >
      <!-- title text and switch button -->
      <br></br>
      <br></br>

      <b-card>
        <h3>{{ $t('pricing.header') }} </h3>
      </b-card>
      <!-- <h1>value:{{ isHaveTrial }}</h1> -->
      <!-- <b-card-title>
          {{ $t('pricing.desc') }}

        </b-card-title> -->

      <!--/ title text and switch button -->

      <!-- pricing plan cards -->
      <b-row class="pricing-card">
        <b-col
          offset-sm-2
          sm="3"
          md="12"
          offset-lg="3"
          lg="3"
          class="mx-auto"
        >
          <b-row>
            <b-card>

              <!-- Multi Range -->
              <div class="multi-range-price">
                <h6 class="filter-title mt-0">
                  {{ $t('pricing.leftSideHeader') }}
                </h6>
                <br>
                <b-form-radio-group
                  v-show="$store.state.locale.locale ==='en'"
                  v-model="userType"
                  class="price-range-defined-radio-group"
                  stacked
                  :options="userTypes"
                />
                <b-form-radio-group
                  v-show="$store.state.locale.locale ==='ar'"
                  v-model="userType"
                  class="price-range-defined-radio-group"
                  stacked
                  :options="arUserTypes"
                />
              </div>
            </b-card>
          </b-row>
        </b-col>
        <b-col
          offset-sm-2
          sm="9"
          md="12"
          offset-lg="2"
          lg="9"
          class="mx-auto"
        >
          <b-row>
            <b-col
              v-for="plan in filteredItems"
              :key="plan._id"
              md="4"
            >
              <b-card
                class="pt-0 mt-0 pricing-card-body"
                align="center"
              >
                <b-card-body class="d-flex flex-column">

                  <!-- img -->

                  <!--/ img -->
                  <h2 v-if="$store.state.locale.locale ==='en'">
                    {{ plan.name }} <b-img
                      v-if="plan.image"
                      :src="plan.image"
                      height="40%"
                      width="40%"
                      class="mb-1 mt-1"
                      alt="basic svg img"
                    />
                  </h2>
                  <h2 v-if="$store.state.locale.locale ==='ar'">
                    {{ plan.arName }}  <b-img
                      v-if="plan.image"
                      :src="plan.image"
                      height="40%"
                      width="40%"
                      class="mb-1 mt-1"
                      alt="basic svg img"
                    />

                  </h2>
                  <!-- <b-card-text>
                  {{ pricing.basicPlan.subtitle }}
                </b-card-text> -->

                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price mt-1">
                      <sup class="font-medium-1 font-weight-bold text-primary">{{ plan.currency }}</sup>
                      <span class="pricing-basic-value font-weight-bolder text-primary">{{ plan.price }}</span>
                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ $t('pricing.year') }}</sub>
                    </div>
                  <!-- <small
                    v-show="!monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >USD {{ pricing.basicPlan.yearlyPlan.totalAnual }} / year</small> -->
                  </div>
                  <!--/ annual plan -->

                  <!-- plan benefit -->
                  <b-list-group class="list-group-circle text-left pb-0">
                    <b-card-text
                      v-if="$store.state.locale.locale ==='en'"
                      v-html="plan.content"
                    />
                    <b-card-text
                      v-if="$store.state.locale.locale ==='ar'"
                      v-html="plan.arContent"
                    />
                  </b-list-group>
                  <!--/ plan benefit -->

                  <!-- buttons -->
                  <div class="plan-btn">
                    <b-button
                      v-if="plan.name=='Silver Plan'"
                      v-ripple.50="'rgba(156,156,156, 0.55)'"
                      style="background-color:rag(156,156,156) !important;"
                      block
                      class="mt-auto align-self-end btn"
                      @click="handelSelectedPlan(plan._id)"
                    >
                      {{ (user && user.activePlanId && plan._id===user.activePlanId) ? $t('pricing.plan'): $t('pricing.upgrade') }}
                    </b-button>
                    <b-button
                      v-if="plan.name=='Golden Plan'"
                      v-ripple.50="'rgba(200,153,47, 0.55)'"
                      style="background-color:#C8992F !important;"
                      block
                      class="mt-auto align-self-end btn"
                      @click="handelSelectedPlan(plan._id)"
                    >
                      {{ (user && user.activePlanId && plan._id===user.activePlanId) ? $t('pricing.plan'): $t('pricing.upgrade') }}
                    </b-button>
                    <b-button
                      v-if="plan.name=='Platinum Plan'"
                      v-ripple.50="'rgba(62,67,123, 0.55)'"
                      style="background-color:#4A4D94 !important;"
                      block
                      class="mt-auto align-self-end btn"
                      @click="handelSelectedPlan(plan._id)"
                    >
                      {{ (user && user.activePlanId && plan._id===user.activePlanId) ? $t('pricing.plan'): $t('pricing.upgrade') }}
                    </b-button>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!--/ pricing plan cards -->

      <!-- pricing free trial -->
      <!--/ pricing free trial -->

      <!-- pricing faq -->
    <!--/ pricing faq -->
    </section>
    <Footer class="footer-companyList" />
  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BFormRadioGroup,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import moment from 'moment'
import Header from '@/layouts/landpage/Header.vue'
import Footer from '@/layouts/landpage/Footer.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/* eslint-disable global-require */
export default {
  components: {
    BButton,
    Header,
    Footer,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BBadge,
    BCardText,
    BImg,
    AppCollapse,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      silver: '156,156,156',
      golden: '200,153,47',
      plantinum: '62,67,123',
      userType: 'company',
      userTypes: [
        { text: 'Company', value: 'company' },
        { text: 'FreeLancer', value: 'freelancer' },
        { text: 'Advisor', value: 'advisor' },
        { text: 'Broker', value: 'broker' },
        { text: 'Partner', value: 'partner' },
        { text: 'Investor', value: 'investor' },
      ],
      arUserTypes: [
        { text: 'شركات', value: 'company' },
        { text: 'مستقلين', value: 'freelancer' },
        { text: 'مستشارين', value: 'advisor' },
        { text: 'وسطاء', value: 'broker' },
        { text: 'شركاء', value: 'partner' },
        { text: 'مستثمرين', value: 'investor' },
      ],
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      boxOne: '',
      boxTwo: '',
    }
  },
  computed: {
    ...mapGetters({
      plans: 'profile/getAllPlans',
      user: 'auth/user',
      isAuth: 'auth/isAuth',
      getTrialCount: 'auth/getTrialCount',
      getTrialExpirationDate: 'auth/getTrialExpirationDate',
    }),
    isHaveTrial() {
      // Date.parse(this.getTrialExpirationDate) > new Date()
      if (this.getTrialCount === 1 && (this.getTrialExpirationDate === '' || this.getTrialExpirationDate === null)) return true
      return false
    },
    filteredItems() {
      return this.plans.filter(
        item => item.type.toLowerCase().indexOf(this.userType.toLowerCase()) > -1,
      )
    },
    planId() {
      return this.user.subscriptions.find(o => o.isActive === true) || {}
    },
  },
  created() {
    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' })
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' })
    this.fetchPlans()
  },
  methods: {
    ...mapActions({
      fetchPlans: 'profile/retreivePlans',
      registerInPlan: 'profile/registerInPlan',
      registerInTrialPlan: 'profile/registerInTrialPlan',
      dispatchAttempt: 'auth/attempt',
    }),
    trialCheckout(id) {
      this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm(this.$t('trial.desc'), {
          title: this.$t('trial.header'),
          size: 'sm',
          okVariant: 'primary',
          cancelVariant: 'outline-secondary',
          okTitle: this.$t('pricing.ok'),
          cancelTitle: this.$t('pricing.cancel'),
        })
        .then(value => {
          if (value) {
            const formData = new FormData()
            formData.append('planId', id)
            formData.append('userId', this.user._id)
            this.registerInTrialPlan(formData)
              .then(response => {
                store.commit('auth/SET_ACCESS_TOKEN', response.data.access_token)
                this.dispatchAttempt(response.data.access_token)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.error.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    takePlan(id) {
      // && (this.user.role.name === 'User')
      if (this.isAuth && (this.user.subscriptions.length === 1)) {
        this.boxOne = ''
        this.$bvModal
          .msgBoxConfirm(`${this.$t('pricing.modelSubH1')} -${this.$store.state.locale.locale === 'en' ? this.user.subscriptions[0].planName : this.user.subscriptions[0].arPlanName} - ${this.$t('pricing.modelSubH2')} ${moment(String(this.user.subscriptions[0].endDate)).format('LL')} ${this.$t('pricing.modelSubH3')}`, {
            title: this.$t('pricing.modelHeader'),
            size: 'sm',
            okVariant: 'primary',
            cancelVariant: 'outline-secondary',
            okTitle: this.$t('pricing.ok'),
            cancelTitle: this.$t('pricing.cancel'),
          })
          .then(value => {
            if (value) {
              const formData = new FormData()
              formData.append('planId', id)
              formData.append('userId', this.user._id)
              formData.append('subId', this.user.subscriptions[0]._id ? this.user.subscriptions[0]._id : null)
              formData.append('stripeId', this.user.subscriptions[0].subscriptionId)
              this.registerInPlan(formData)
                .then(response => {
                  window.location.href = response.data.url
                })
                .catch(error => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.response.data.error.message,
                      icon: 'EditIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      } else if (this.isAuth && (this.user.subscriptions.length === 0)) {
        const formData = new FormData()
        formData.append('planId', id)
        formData.append('userId', this.user._id)
        this.registerInPlan(formData)
          .then(response => {
            window.location.href = response.data.url
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.error.message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('pricing.unRegisteredMSG'),
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.$router.push({ path: '/login' })
      }
    },
    handelSelectedPlan(id) {
      if (this.isAuth && (this.user.subscriptions.length === 0) && this.isHaveTrial) {
        this.trialCheckout(id)
      } else if (this.isAuth && Date.parse(this.getTrialExpirationDate) > Date.parse(new Date())) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.$t('trial.warningDesc')} : ${this.getTrialExpirationDate}`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        this.takePlan(id)
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
<style scoped>
@media (min-width: 992px) {
  .footer-companyList {
    margin-left: -28px;
    margin-right: -30px;
    margin-bottom: -22px;
  }
}

[dir] .list-group.list-group-circle .list-group-item:after {
  background-image: none;
}
.pricing-card-body .card-body {
  min-height: 644px;
}

.plan-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 35px;
}
</style>
